<!--
 * @Author: jiang
 * @Date: 2021-06-11 16:12:55
 * @Description: 任务落实
-->
<template>
  <el-form
    ref="form"
    class="g-p-20"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >
    <el-row :gutter="10">
      <el-col>
        <el-form-item
          label="发文说明"
          prop="name"
        >
          <el-input
            v-model="formData.name"
            type="textarea"
            placeholder="输入内容"
          ></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="8">
        <el-form-item
          label="任务落实是否需上传附件"
          prop="medias_set"
        >
          <el-radio-group v-model="formData.medias_set">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col> -->
      <el-col :span="8">
        <el-form-item
          label="收文批复截止日期"
          prop="executive_end_data"
        >
          <el-date-picker
            style="width: 100%;"
            v-model="formData.executive_end_data"
            value-format="yyyy-MM-dd"
            placeholder="请选择日期"
          ></el-date-picker>
        </el-form-item>
      </el-col>
     <!-- <el-col :span="8">
        <el-form-item
          label="任务目录"
          prop="meeting_id"
        >
          <task-cate-select v-model="formData.meeting_id"></task-cate-select>
        </el-form-item>
      </el-col> -->
 <!--     <el-col :span="24">
        <el-form-item
          label="任务落实部门"
          prop="executive_departments"
        >
          <b-select-dept
            v-model="formData.executive_departments"
            multiple
          ></b-select-dept>
        </el-form-item>
      </el-col> -->
      <el-col :span="24">
        <el-form-item label="收文人员">
          <b-select-user
            v-model="formData.executive_users"
            multiple
          ></b-select-user>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item label="图片">
          <c-upload-image v-model="formData.images"></c-upload-image>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item label="附件">
          <c-upload-media
            v-model="formData.media_keys"
            :default="defaultMedia"
          ></c-upload-media>
        </el-form-item>
      </el-col>
      <el-col class="g-center">
        <b-btn-confirm
          :loading="submitLoading"
          @click="onSubmit"
        ></b-btn-confirm>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import CUploadImage from '@/components/upload/image'
import CUploadMedia from '@/components/upload/media'
import BSelectDept from '@/components/select/cascader/dept'
import BSelectUser from '@/components/select/user'
import TaskCateSelect from '@/components/select/cascader/task'
import { createTask, updateTask } from '@/api/task'

export default {
  components: {
    CUploadImage,
    CUploadMedia,
    BSelectDept,
    BSelectUser,
    TaskCateSelect,
  },
  props: {
    // submitLoading: {
    //   type: Boolean,
    //   default: false,
    // },
    data: {},
  },
  data() {
    return {
      submitLoading: false,
      defaultMedia: [],
      formData: {},
      formRules: {
        name: [{ required: true, message: '必填项', trigger: 'blur' }],
        executive_end_data: [{ required: true, message: '必填项', trigger: 'change' }],
      },
    }
  },
  watch: {
    data: {
      handler(data) {
        if (data) {
          this.formData = this.$tools.copy(data)
        }
        if (data?.defaultMedia) {
          this.defaultMedia = data.defaultMedia
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.formData.name,
            executive_end_data: this.formData.executive_end_data,
            executive_departments: this.formData.executive_departments,
            executive_users: this.formData.executive_users,
            supervisor_user_id: this.formData.supervisor_user_id,
            images: this.formData.images,
            media_keys: this.formData.media_keys,
            medias_set: this.formData.medias_set,
            meeting_id: this.formData.meeting_id,
          }

          this.submitLoading = true
          if (this.formData.id) {
            updateTask(this.formData.id, params)
              .then(res => {
                this.$message.success('发文更新成功')
                this.$emit('success')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            createTask(params)
              .then(res => {
                this.$message.success('发文成功')
                this.$emit('success')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        }
      })
    },
  },
}
</script>
